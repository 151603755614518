a {
  color: burlywood;
}

lined {
  text-decoration: line-through;
}

.blog-post {
  color: #e0e0e0;
  background: linear-gradient(10deg, #000000, #00435f);
  background-repeat: no-repeat;
  background-attachment: fixed;
  font-family: 'Arial', sans-serif;
  line-height: 1.8;
  max-width: 800px;
  min-height: 100vh;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
}

.blog-post.theme-light {
  background: #ffffff;
  color: #000000;
}

.blog-post.theme-sepia {
  background: #f4ecd8;
  color: #4b3832;
}

.blog-post.theme-dark {
  background: #1e1e1e;
  color: #e0e0e0;
}

.blog-post.theme-old {
  background: linear-gradient(10deg, #000000, #00435f);
  background-repeat: no-repeat;
  background-attachment: fixed;
  color: #e0e0e0;
}


.blog-post h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 25px;
  line-height: 1.3;
}

.blog-post h2 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
}

.blog-post p {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 30px;
}

.blog-post ul {
  margin-left: 20px;
}

.blog-post blockquote {
  font-style: italic;
  color: #555;
  margin: 20px 0;
  padding-left: 10px;
  border-left: 3px solid #ccc;
}

.blog-post .navigate {
  max-width: 200px;
  margin: 18px auto auto;
  display: flex;
}

.blog-post .navigate img {
  width: 30px;
  height: 30px;
  margin-top: -5px;
  padding: 5px;
  background: black;
  border-radius: 20px;
  position: absolute;
  cursor: pointer;
}

.blog-post .navigate button {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  margin: 0 auto;
  padding: 8px 20px;
  background-color: #D25B5B;
  border: none;
  border-radius: 5px;
  transition: .2s;
}

.blog-post .navigate button:hover {
  filter: brightness(0.8);
  cursor: pointer;
}

.blog-post .navigate button:disabled {
  filter: grayscale(1);
  cursor: auto;
}


.author-box {
  margin: 20px 0;
  gap: 10px;
  display: grid;
}

.author {
  display: flex;
  gap: 20px;
  padding-left: 20px !important;
  transform: none !important;
}

.author img {
  width: 60px !important;
  height: 60px !important;
  border-radius: 50%;
  object-fit: cover;
}

.author .column-div {
  gap: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.author h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
  line-height: normal;
}

.author strong {
  font-size: 18px;
  font-weight: 600;
  color: #ccc;
  line-height: normal;
}



.rating-box {
  color: #e0e0e0;
  margin: 20px 0 30px;
  padding: 10px 0 15px;
  text-align: center;
  background: linear-gradient(345deg, #009c1c, #000000);
  border-radius: 12px;
}

.comments-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 10px;
}

.comment {
  position: relative;
  background: #333;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  transition: transform .3s;
  color: #fff;
}

.comment:hover {
  transform: scale(1.02);
}

.comment .left {
  display: flex;
  align-items: center;
  gap: 10px;
}

.comment img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.comment .left strong {
  font-weight: bold;
  font-size: 16px;
}

.comment p {
  margin: 0;
  font-size: 14px;
  color: #bdc3c7;
  text-align: left;
  line-height: 1.6;
  padding-top: 8px;
}

.comment .date {
  font-size: 12px;
  color: #95a5a6;
  float: right;
}

.comment-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 600px;
  margin: 0 auto 20px;
  padding: 20px;
  border-radius: 8px;
}

.comment-form input, .comment-form textarea {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
}

.comment-form textarea {
  min-height: 72px;
}

.comment-form button {
  padding: 12px;
  background: #D25B5B;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  transition: .2s;
}

.comment-form button:hover {
  filter: brightness(0.8);
}

.error-message {
  color: red;
  font-size: 14px;
}

.user-rating {
  font-size: 18px;
  margin-bottom: -12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.star-rating {
  display: flex;
  justify-content: center;
}

.star {
  font-size: 36px;
  cursor: pointer;
  color: gray;
  transition: color 0.2s;
}

.star.selected {
  color: gold;
}

.average-rating {
  font-size: 40px;
  font-weight: bold;
  margin-right: 10px;
  text-align: center;
}

.alert {
  width: 90%;
  font-weight: 700;
  margin: 10px auto 0;
  padding: 10px 8px;
  border: 3px solid gold;
  border-image-slice: 1;
  border-radius: 10px;
  line-height: 1.5;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .blog-post p {
    line-height: 1.6;
  }

  .blog-post h1 {
    font-size: 30px;
  }
}